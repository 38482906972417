.tall-button {
    height: 100%;
    float: left;
}

.tooltip-admin .tooltiptext-admin {
    visibility: hidden;
    background-color: black;
    color: white;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    max-width: 300px;

    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    white-space: normal;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    margin-top: 10px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-admin:hover .tooltiptext-admin {
    visibility: visible;
}

.address-search-input {
    margin-top: 15px !important;
}

.pac-container {
    z-index: 10000;
}
