.home-header-image {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
}

.home-header-overlay {
    background-color: #21252996;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.home-header-content {
    position: absolute;
    display: flex;
    height: calc(100vh - 70px);
    align-items: left;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
    z-index: 2;
}

.project-scroll-container {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 15px;
    scroll-behavior: smooth;
    position: relative;
}

.project-scroll-container > *:not(:last-child) {
    margin-right: 48px;
}
