.auction {
    display: flex;
}

.auction-media {
    flex: 1;
    position: sticky;
    top: 70px;
    margin-bottom: 8px;
    z-index: 100;
}
.auction-media-width {
    /*width: calc(100vw - 630px);*/
}

.auction-details {
    padding: 45px 45px 12px 45px;
    /*width: 630px;*/
}

.react-autocomplete-input > li {
    float: left;
    clear: left;
    margin: 0px;
}

.react-autocomplete-input {
    max-height: 166px;
    z-index: 10;
    overflow-y: scroll;
    margin-top: 30px;
}

.auction-image-container {
    flex: 1;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: black;
}

.auction-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
}

.auction-pdf {
    margin-left: calc(50% - 300px);
    text-align: center;
}

.auction-overlay-bottom {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
}
.auction-image-buttons {
    display: flex;
    cursor: pointer;
}
.auction-image-button {
    width: 130px;
    height: 150px;
    background-color: white;
    text-align: center;
    border-style: solid;
    border-color: #ccc;
    border-width: 1px;
    z-index: 1;
}

.auction-image-dot {
    color: white;
    cursor: pointer;
    width: 15px !important;
}

.thumbnail-container {
    /*width: calc(100vw - 630px);*/
    height: 145px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 3px 3px;
}
.thumbnail-container > *:not(:last-child) {
    margin-right: 3px;
}
.thumbnail-image {
    width: 250px;
    max-width: 250px;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
}
.thumbnail-selected {
    outline: 3px solid #1874ff;
}

.input-focus {
    color: #212529;
    -webkit-transition: color 150ms ease-in;
    -moz-transition: color 150ms ease-in;
    -o-transition: color 150ms ease-in;
    transition: color 150ms ease-in;
}

.input-unfocused {
    color: #aaa;
}

.bid-input {
    outline-width: 0px !important;
    width: 100%;
    padding-left: 10px;
    margin-right: 10px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    color: #212529;
}

.underline-thin {
    border-top-width: 0px !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-bottom-width: 1px !important;
    border-color: #ccc;
    border-style: solid;
}

.auction-description {
    max-height: 120px;
    position: relative;
    overflow: hidden;
}

.view-more-fade {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    background-image: linear-gradient(to bottom, transparent, white);
}

.highlights {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}
.bullets li {
    list-style: none;
    padding-right: 10px;
}
.bullets li::before {
    content: '\2022';
    color: #212529;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: x-large;
    height: 5px;
    font-family: inter;
}

.auction-arrow-left {
    left: 10px;
}
.auction-arrow-right {
    right: 10px;
}
.auction-arrow {
    position: absolute;
    top: calc(50% - 21px);
    width: 42px;
    height: 42px;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 100%;
    z-index: 1;
}

.bid-history-container {
    max-height: 250px;
    overflow-y: auto;
}
.bid-history-item {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.ql-editor {
    height: 300px !important;
}

.project-banner-overlay {
    background-color: #21252996;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}
