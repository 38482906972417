.horizontal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.horizontal-list > li {
    float: left;
    margin-right: 8px;
}

.description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 80px;
}

.property-card-overlay {
    background-color: #21252970;
    /*-webkit-transition: background-color 150ms ease-out;
    -moz-transition: background-color 150ms ease-out;
    -o-transition: background-color 150ms ease-out;
    transition: background-color 150ms ease-out;*/
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

/*
.property-card-overlay:hover {
    background-color: #21252933;
    -webkit-transition: background-color 150ms ease-in;
    -moz-transition: background-color 150ms ease-in;
    -o-transition: background-color 150ms ease-in;
    transition: background-color 150ms ease-in;
}
*/

.property-card-status {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 38px;
    margin-right: 20px;
    pointer-events: none;
    z-index: 10;
}

.property-card-follow {
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 25px;
    z-index: 10;
}

.property-card-details {
    min-height: 200px;
    pointer-events: none;
    box-sizing: initial;
    max-width: 100%;
    background-color: #f4f4f4bb;
    border-radius: 0 0 25px 25px;
}

.property-card-horizontal-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.property-card-admin-menu {
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 20;
}

.property-card-admin-controls {
    display: none;
    opacity: 0;
    -webkit-transition: opacity 100ms ease-out;
    -moz-transition: opacity 100ms ease-out;
    -o-transition: opacity 100ms ease-out;
    transition: opacity 100ms ease-out;
}

.property-card:hover .property-card-admin-controls {
    display: block;
    opacity: 1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    -o-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
}

.watch-button-fade {
    opacity: 0;
    -webkit-transition: opacity 100ms ease-out;
    -moz-transition: opacity 100ms ease-out;
    -o-transition: opacity 100ms ease-out;
    transition: opacity 100ms ease-out;
}

.property-card:hover .watch-button-fade {
    opacity: 1;
    -webkit-transition: opacity 100ms ease-in;
    -moz-transition: opacity 100ms ease-in;
    -o-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
}

.circle {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;
}

.property-card-concluded {
    margin-top: 20px;
}
