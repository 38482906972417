.App {
    text-align: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    flex-direction: column;
    margin: auto;
}

.clickable {
    cursor: pointer;
}

html {
    background-color: #fff;
}

body {
    background-color: #fff;
}

.semibold {
    font-weight: 550 !important;
}

.bold {
    font-weight: 650 !important;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.flex {
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.gap-small {
    margin-top: 30px;
}

.gap {
    margin-top: 40px;
}

.gap-large {
    margin-top: 50px;
}

.colour-live {
    color: #0f0;
}
.colour-live-fill {
    background-color: #0f0;
}
.colour-coming {
    color: #ffc000;
}
.colour-coming-fill {
    background-color: #ffc000;
}
.colour-done {
    color: white;
}
.colour-done-fill {
    background-color: white;
}

.ignore-click {
    pointer-events: none;
}

.link {
    text-decoration: none !important;
}

.underline:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #212529 !important;
}

.hidden {
    display: none;
}

.sharp {
    border-radius: 0 !important;
}

.sharp .MuiOutlinedInput-root {
    border-radius: 0px;
}

footer {
    /*border-top: 1px solid #dbdbdb;*/
}

hr {
    border-color: #707070 !important;
    background-color: #707070 !important;
    color: #707070 !important;
}

strong {
    font-weight: 700 !important;
}

.modal-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-input {
    margin-bottom: 25px !important;
}

/*.text-input-container {
    display: flex;
    justify-content: space-between;
}
.text-input-container > *:not(:last-child) {
    margin-right: 10px;
}*/

.text-input > .form-control > div > input {
    padding: 15px 15px 10px 15px;
}

.image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu-item-overlay {
    background-color: transparent;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.menu-item-overlay:hover {
    background-color: #00000006;
}

.Toastify__toast {
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 2px 4px;
}

.Toastify__toast > button {
    position: absolute;
    top: 5px;
    right: 8px;
}

.Toastify__toast-body {
    padding: 5px;
    margin: 0px;
    margin-right: 15px;
}

.Toastify__toast-icon {
    margin-right: 15px;
}

.Toastify__toast-container {
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    z-index: 100;
}

.parser > p {
    margin-bottom: 0px !important;
}

.blur {
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.ReactModal__Overlay {
    z-index: 1100 !important;
}

.react-autocomplete-input {
    overflow-y: auto;
    border-radius: 15px;
    padding: 5px;
}

.react-autocomplete-input > li.active {
    background-color: #212529;
    color: white;
}

.react-autocomplete-input > li {
    border-radius: 10px;
    width: 100%;
}

.shadow0 {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 7px 1px;
    position: relative;
    -webkit-transition: box-shadow 300ms ease-out;
    -moz-transition: box-shadow 300ms ease-out;
    -o-transition: box-shadow 300ms ease-out;
    transition: box-shadow 300ms ease-out;
}

.shadow1 {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 7px 2px;
    position: relative;
    -webkit-transition: box-shadow 300ms ease-out;
    -moz-transition: box-shadow 300ms ease-out;
    -o-transition: box-shadow 300ms ease-out;
    transition: box-shadow 300ms ease-out;
}

.shadow1:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 8px;
    -webkit-transition: box-shadow 200ms ease-in;
    -moz-transition: box-shadow 200ms ease-in;
    -o-transition: box-shadow 200ms ease-in;
    transition: box-shadow 200ms ease-in;
}

.clamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.clamp3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.persona-widget__iframe {
    width: 100%;
}

.MuiDataGrid-row {
    background-color: #ffffff;
}
.MuiDataGrid-row:hover {
    background-color: #f8f8f8 !important;
}
.MuiDataGrid-row.Mui-selected {
    background-color: #eee !important;
}
.MuiDataGrid-virtualScroller {
    overflow-y: hidden !important;
}

.row-danger {
    background-color: #ffdddd;
}
.row-danger:hover {
    background-color: #ffcfcf !important;
}
.row-danger.Mui-selected {
    background-color: #ffcfcf !important;
}

.row-warning {
    background-color: #fffbda;
}
.row-warning:hover {
    background-color: #fffdcd !important;
}
.row-warning.Mui-selected {
    background-color: #fffdcd !important;
}

.row-disabled {
    background-color: #f0f0f0;
}
.row-disabled:hover {
    background-color: #e6e6e6 !important;
}
.row-disabled.Mui-selected {
    background-color: #e6e6e6 !important;
}

@keyframes background-scroll-left {
    0% {
        background-position: 0;
    }
    100% {
        background-position: -1000px;
    }
}

.list-table-selected-item {
    background-color: #212529 !important;
    color: white !important;
    cursor: pointer;
}

.list-table-item {
    background-color: transparent !important;
    color: #212529 !important;
    cursor: pointer;
}

.list-table-item:hover {
    background-color: #ddd;
}

/* Hotglue dialog */
.hotglue-w-MuiPaper-root {
    max-width: 800px !important;
}
