.category-card {
    position: relative;
}

.category-card-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    -webkit-transition: background-color 150ms ease-out;
    -moz-transition: background-color 150ms ease-out;
    -o-transition: background-color 150ms ease-out;
    transition: background-color 150ms ease-out;
}

.category-card-overlay:hover {
    background-color: #00000050;
    -webkit-transition: background-color 150ms ease-in;
    -moz-transition: background-color 150ms ease-in;
    -o-transition: background-color 150ms ease-in;
    transition: background-color 150ms ease-in;
}

.category-card-text {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: white;
    width: calc(100% - 30px);
}

.category-card-icon {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: white;
}

.category-page-header-overlay {
    background-color: #00000080;
    position: absolute;
    top: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
