.input-label {
    margin-bottom: 3px;
    font-size: small;
    color: #888;
}

.form-group {
    margin-bottom: 30px;
}

.input-panel-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.input-panel-item {
    flex: 1;
}
.input-panel-container > *:not(:last-child) {
    margin-right: 10px;
}

.btn-wide {
    width: 100%;
}

.side-panel {
    position: -webkit-sticky;
    position: sticky;
    top: 16px;
    height: calc(100vh - 90px);
    overflow-y: auto;
}

.top-right {
    position: absolute;
    top: 23px;
    right: 23px;
}

h5 {
    margin-bottom: -3px;
    text-align: center;
}

h6 {
    text-align: center;
}

input[type='file'] {
    display: none;
}

.fill-input-container {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.fill-input {
    width: calc(100% - 45px);
}

.side-button {
    width: 38px;
    height: 38px;
    margin-top: 22px;
}

.highlight-list {
    list-style: none;
    padding: 0;
    margin-top: 6px;
}

.highlight-list > li {
    padding: 7px 7px 7px 10px;
    border-style: solid;
    border-color: #ddd;
    border-width: 1px;
    margin: 5px;
    border-radius: 5px;
}

.amenity-list {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.sortable-ghost {
    opacity: 0.6;
}

.button-small {
    margin-top: 0px;
    width: 25px;
    height: 25px;
    font-size: small;
    padding: 0px;
}

.hover-li .button-small {
    visibility: hidden;
}

.hover-li:hover .button-small {
    visibility: visible;
}

.href:hover {
    text-decoration: underline;
    cursor: pointer;
}

.image-container {
    min-height: 118px;
    background-color: #ddd;
    border: 2px dashed #999;
}

.draggable-media {
    background-color: #212529;
    position: relative;
    border-radius: 5%;
}

.draggable-media .button-small {
    visibility: hidden;
}

.can-delete > .draggable-media:hover .button-small {
    visibility: visible;
}

.media-label {
    position: absolute;
    font-size: small;
    white-space: nowrap;
    background-color: black;
    color: white;
    text-align: left;
    padding-right: 5px;
    vertical-align: middle;
    margin: 0px !important;
}
.media-label-type {
    left: 0px;
    top: 0px;
    height: 20px;
}

.media-label-title {
    left: 0px;
    bottom: 0px;
    height: 20px;
}
.draggable-media .media-label-title {
    pointer-events: none;
}
.can-delete > .draggable-media:hover .media-label-title {
    pointer-events: all;
}

.draggable-media .media-label {
    opacity: 0.5;
}

.draggable-media:hover .media-label {
    opacity: 1;
}

.top-right-button {
    position: absolute;
    right: 2px;
    top: 2px;
}

.hidden {
    height: 0px;
    width: 0px;
}

#project-media {
    overflow: hidden !important;
    min-height: 118px;
    margin-bottom: 0px;
}

#property-media {
    overflow: hidden !important;
    min-height: 118px;
    margin-bottom: 0px;
}

.sortable-chosen {
    background-color: #ddd;
}

.media-modal-section {
    height: 700px;
    overflow-y: auto;
    padding: 20px 10px 20px 10px;
}
