.user-content-width {
    padding-bottom: 0px;
    background-color: white;
}
.user-content {
    margin-top: 30px;
    margin-bottom: 30px;
}

.user-path-container {
    width: 100%;
    justify-content: space-between;
    /*justify-content: center;*/
    display: flex;
    padding-bottom: 10px;
}
.user-path {
    color: inherit !important;
    text-decoration: none;
    /*margin: 20px 20px 15px 20px;*/
    padding-bottom: 4px;
    display: flex;
}
.user-active {
    /*border-style: solid;
    border-width: 0px;
    border-bottom-width: 2px;
    border-color: #212529;*/
    font-weight: 700;
}
