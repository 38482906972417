/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: 400;
    src: local('Optima'), url('OPTIMA.woff') format('woff');
}

@font-face {
    font-family: 'Optima';
    font-style: italic;
    font-weight: 400;
    src: local('Optima Italic'), url('Optima_Italic.woff') format('woff');
}

@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: 500;
    src: local('Optima Medium'), url('Optima Medium.woff') format('woff');
}

@font-face {
    font-family: 'Optima';
    font-style: normal;
    font-weight: 700;
    src: local('Optima Bold'), url('OPTIMA_B.woff') format('woff');
}
