.project-card {
    box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
    position: relative;
    -webkit-transition: box-shadow 300ms ease-out;
    -moz-transition: box-shadow 300ms ease-out;
    -o-transition: box-shadow 300ms ease-out;
    transition: box-shadow 300ms ease-out;
}

.project-card:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 7.5px 7.5px 7px;
    -webkit-transition: box-shadow 200ms ease-in;
    -moz-transition: box-shadow 200ms ease-in;
    -o-transition: box-shadow 200ms ease-in;
    transition: box-shadow 200ms ease-in;
}
